// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import {PewComponent} from "../../../../assets/raw/js/components/pew-component";

export class SliderCarouselComponent extends PewComponent {
  constructor(element, options) {

    let defaultOptions = {
      a11y: true,
      navigation: true,
      loop: true,
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 20,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      /*pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      },*/
      breakpoints: {
        783: {
          slidesPerView: 3,
          speed: 500,
        },
        960: {
          slidesPerView: 4,
        },
        1280: {
          slidesPerView: 5,
        }
      }
    };
    super(element, Object.assign(defaultOptions, options));
  }

  init() {
    this.prepareMarkup();
    this.slider = new Swiper(this.element.parent()[0], this.options);
  }

  prepareMarkup() {
    // Create a wrapper for this.element, that has the class swiper
    this.element.wrap('<div class="swiper-outer-wrap"><div class="swiper"></div></div>');
    // Add the class swiper-container to this.element + remove the class items-list that conflicts with sizes calculation
    this.element.addClass('swiper-wrapper').removeClass('items-list');
    // Add the class swiper-slide to all children of this.element
    this.element.find('>*').addClass('swiper-slide');

    const $outerWrap = this.element.parent().parent();

    // if this slider has pagination, add a div with class swiper-pagination
    if (this.options.pagination) {
      $outerWrap.append('<div class="swiper-pagination"></div>');
      this.options.pagination.el = $outerWrap.find('.swiper-pagination')[0];
    }

    // if this slider has navigation, add a div with class swiper-button-next and swiper-button-prev
    if (this.options.navigation) {
      $outerWrap.append('<div class="swiper-button-next"></div><div class="swiper-button-prev"></div>');
      this.options.navigation = {
        nextEl: $outerWrap.find('.swiper-button-next')[0],
        prevEl: $outerWrap.find('.swiper-button-prev')[0]
      }
    }
  }

  destroy() {
    if (this.slider) {
      this.slider.destroy();
      // Remove the wrapper and the classes we added
      this.element.unwrap().unwrap().removeClass('swiper-wrapper').addClass('items-list');
      this.element.find('>*').removeClass('swiper-slide');
      // Remove the pagination and navigation elements
      this.element.parent().find('.swiper-pagination').remove();
      this.element.parent().find('.swiper-button-next').remove();
      this.element.parent().find('.swiper-button-prev').remove();
    }
  }
}

window
  .pew
  .addRegistryEntry({
    key: 'wdf-slider-carousel',
    domSelector: '[class$="wdf-slider-carousel"]',
    classDef: SliderCarouselComponent
  });
